import React, { useEffect, useState } from 'react';
import { Box, Flex, Input, Button, Text } from '@chakra-ui/react';
import Map from '../views/tariffs/Map';
import {
  PictureSeancesIndividuelles,
  PictureBaladeCollectives,
  PictureSeancesCollectives,
} from '../assets';
import Container from '../components/Container';
import Tile from '../components/Tile';
import ReCAPTCHA from 'react-google-recaptcha';
import { headers } from '../headers';
import Header from '../components/Header';

function Tariffs() {
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [userAddress, setUserAddress] = useState(null);
  const [geocodeAddress, setGeocodeAddress] = useState(null);
  const [distance, setDistance] = useState(null);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const recaptchaRef = React.createRef();

  const getPrice = () => {
    if (!distance) return null;
    const formattedDistance = parseInt(distance, 10);
    const inKm = distance.endsWith('km');
    if (formattedDistance <= 35 || !inKm) return 'gratuit';
    console.log(formattedDistance - 35)
    return `${Math.ceil((formattedDistance - 35) * 0.4)}€`;
  };

  useEffect(() => {
    if (!isCaptchaValid) {
      recaptchaRef.current?.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaptchaValid]);

  return (
    <Container>
      <Box mt={8} fontSize="large">
        J'ai pour volonté de ne pas proposer de forfait, ne pouvant déduire à
        l'avance le nombre de séances nécessaires.
      </Box>
      <Flex></Flex>
      <Text fontSize="large">
        ⚠️ N'ayant pas de lecteur de carte, je n'accepte que les paiements{' '}
        <Text fontWeight="bold" as="span">
          en espèces
        </Text>
        .
      </Text>
      <Flex
        direction="row"
        gap={8}
        mt={8}
        flexWrap="wrap"
        justifyContent="center"
      >
        <Tile
          title="Séances individuelles"
          picture={PictureSeancesIndividuelles}
        >
          <>
            <Box>
              1ère séance individuelle de 1h : <span>45€</span>
            </Box>
            <Box>
              Suivantes à <span>40€</span>
            </Box>
          </>
        </Tile>
        <Tile
          title="Séances collectives"
          picture={PictureSeancesCollectives}
          imageProps={{
            objectPosition: 'top',
          }}
        >
          <>
            <Box>1h</Box>
            <Box>20€/🧑</Box>
            <Box>🐶🐶🐶🐶 maximum</Box>
          </>
        </Tile>
        <Tile title="Balades collectives" picture={PictureBaladeCollectives}>
          <>
            <Flex flexDir="row" w="100%">
              <Flex flexDir="column" flex={1} alignItems="center" gap={2}>
                <Box>Chien</Box>
                <Box>1h → 10€/🧑</Box>
                <Box>2h → 15€/🧑</Box>
              </Flex>
              <Flex flexDir="column" flex={1} alignItems="center" gap={2}>
                <Box>Chiot</Box>
                <Box>~45min/1h</Box>
                <Box>15€/🧑</Box>
              </Flex>
            </Flex>
            <Box>🐶🐶🐶🐶 maximum</Box>
          </>
        </Tile>
      </Flex>
      <Flex mt={8} gap={8} w="100%" flexWrap="wrap" justifyContent="center">
        <Flex direction="column" h="482px" w="482px">
          <Map
            address={userAddress}
            setGeocodeAddress={setGeocodeAddress}
            setDistance={setDistance}
          />
        </Flex>
        <Flex
          display="flex"
          direction="column"
          gap={2}
          w="450px"
          bg="white"
          padding={5}
          borderRadius={'xl'}
        >
          <Box>⚫ zone de déplacement gratuite</Box>
          <Box>Calcul des frais de déplacement (0.25€/km):</Box>
          <Input
            type="text"
            placeholder="adresse"
            value={address}
            onChange={event => {
              setAddress(event.target.value);
            }}
            backgroundColor="#f1f1f1"
            _placeholder={{ color: 'gray.800' }}
          />
          <Input
            type="number"
            placeholder="code postal"
            value={zipCode}
            onChange={event => {
              setZipCode(event.target.value);
            }}
            backgroundColor="#f1f1f1"
            _placeholder={{ color: 'gray.800' }}
          />
          <Input
            type="text"
            placeholder="ville"
            value={city}
            onChange={event => {
              setCity(event.target.value);
            }}
            backgroundColor="#f1f1f1"
            _placeholder={{ color: 'gray.800' }}
          />
          <Box alignSelf="center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lfo3XIhAAAAAL1SHLtjUvBUQx94lcLkqk7CSsjS"
              onChange={value => {
                if (value) {
                  setIsCaptchaValid(true);
                }
              }}
              size={
                typeof window !== 'undefined' && window.screen.width >= 1280
                  ? 'normal'
                  : 'compact'
              }
            />
          </Box>
          <Button
            onClick={() => {
              const userAddress = `${address.replace(
                ' ',
                '+'
              )}+${zipCode}+${city.replace(' ', '+')}`;
              setIsCaptchaValid(false);
              setUserAddress(userAddress);
            }}
            disabled={!isCaptchaValid}
            colorScheme="test"
            w="100%"
          >
            Valider
          </Button>
          {geocodeAddress && distance && (
            <>
              <Box>Adresse retenue : {geocodeAddress}</Box>
              <Box>🚗 Distance totale : {distance}</Box>
              <Box>💶 Coût : {getPrice()}</Box>
            </>
          )}
        </Flex>
      </Flex>
      <Box mt={16} />
    </Container>
  );
}

export default Tariffs;

export function Head() {
  return <Header {...headers.tariffs} />;
}
